<template lang="pug">
Container(v-if='greaterThan("desktop")')
	div(class='EmployeesHeader')
		h1 {{ header }}
		UiButton(theme='filled', variant='primary', size='xl', @click='getEmployeesPageModal', rounded) Получить консультацию

Container
	h2(class='text-center short-text') {{ variablesList.employeesSubtitle }}
	div(class='Employees')
		template(v-if='greaterThan("mobileWide")')
			div(v-for='employee in employeesState', :key='employee.id', class='Employee')
				NuxtLink(:to='`${route.path}/${employee.alias}`', class='Photo')
					UiImage(:image='employee.photo', width='600px', lazy)
				NuxtLink(:to='`${route.path}/${employee.alias}`', class='Name') {{ employee.name }} {{ employee.surname }}
				div(class='Skills') {{ employee.skills.join(', ') }}
				div(class='Button')
					UiButton(theme='outline', size='md', @click='getEmployeeModal(employee)') Записаться
		template(v-else)
			UiCarousel(
				class='Employees--Carousel',
				:perView='greaterThan("mobileMedium") ? 2.2 : greaterThan("mobile") ? 1.8 : 1.2',
				ref='slider'
			)
				template(#default='{ carouselItemProps }')
					TransitionGroup(name='slider')
						UiCarouselItem(
							v-for='employee in employeesState',
							:key='employee.id',
							v-bind='carouselItemProps'
						)
							div(class='Employee')
								NuxtLink(:to='`${route.path}/${employee.alias}`', class='Photo')
									UiImage(:image='employee.photo', width='600px', lazy)
								NuxtLink(:to='`${route.path}/${employee.alias}`', class='Name') {{ employee.name }} {{ employee.surname }}
								div(class='Skills') {{ employee.skills.join(', ') }}
								div(class='Button')
									UiButton(theme='outline', size='md', @click='getEmployeeModal(employee)') Записаться

UiSubscribe
</template>

<script lang="ts" setup>
import { Resolver } from '~/models/resolver';
import { Employee } from '~/models/employee';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);

/**
 * Define hooks
 */
const route = useRoute();
const { header } = useMeta();
const { greaterThan } = usePlatform();
const { employeesState, fetchEmployees } = useEmployees();
const { variablesList, fetchVariables } = useVariables();
const { resolverState, fetchResolver } = useResolver();
const { modalState, modalShow } = useModal();

/** Get employees page modal */
const getEmployeesPageModal = () => {
	const { form } = resolverState.value.find(r => r.collection === Resolver.Page.EMPLOYEES) || {};

	if (!form) return;

	modalState.value = {
		header: form.header,
		description: form.description,
		source: form.source,
		subject: form.subject,
		form: form.fields,
		target: form.target,
	};

	modalShow();
};

/**
 * Get employee modal
 */
const getEmployeeModal = (employee: Employee.Model) => {
	if (!employee.form) return;

	modalState.value = {
		header: employee.form.header,
		description: employee.form.description,
		source: employee.form.source,
		subject: employee.form.subject,
		form: employee.form.fields,
		target: employee.form.target,
	};

	modalShow();
};

/**
 * Define fetch
 */
await fetchResolver();
await fetchEmployees();
await fetchVariables();
</script>

<style lang="less" scoped>
.EmployeesHeader {
	.relative(1);
	.grid-aspect(2; 1);
	.box(flex; 100%; auto; none; center; center; center; wrap column);
	.background(none; '@/images/employees-bg.png'; center; bottom center; contain);
	& > h1 {
		margin-top: 4rem;
	}
	& > .UiButton {
		margin-top: 4rem;
	}
}

.Employees {
	padding: 2rem 0 0 0;
	.box(grid; 100%; auto; none; 2rem 2.5rem; auto; auto 1fr auto; stretch; center);

	&--Carousel {
		.grid(1 span; 1 4);
		.box(block; 100%; auto; none);
	}
	& .Employee {
		.box(grid; 100%; 100%; none; 1.25rem; auto auto 1fr auto; 1fr; start; start);
		&:before {
			content: '';
			.grid(2 4; 1 2);
			.box(block; 0.3125rem; 100%; @ColorWhite);
		}
		& > .Photo {
			.grid(1 span);
			.grid-aspect(2; 2.6);
			.relative(1; hidden);
			.box(flex; 100%; auto; none; flex-start; center; center; nowrap row);
			.border-radius(@BorderRadiusMedium);
			.transition(all; 250ms; 'sine');
			&:before {
				content: '';
				.absolute(1; none; 5% 0 0 0);
				.box(block; 100%; auto; @ColorWhite);
				.border-radius(@BorderRadiusMedium);
				.box-shadow(0 0 0 0 fade(@ColorBase, 25%));
				.transition(all; 250ms; 'sine');
			}
			& > img {
				object-fit: contain;
				.relative(2);
				.box(block; 125%; auto);
			}
			&:hover {
				.translateY(-0.1875rem);
				&:before {
					.box-shadow(0 1.5rem 3rem -1.5rem fade(@ColorBase, 25%));
				}
			}
		}
		& > .Name {
			padding: 0 0.625rem 0 1rem;
			.grid(2 3; 1 2);
			.text(@ColorBase; 1.25em 1.5rem @medium; left; none none);
			.transition(all; 250ms; 'sine');
			&:hover {
				.text(@ColorPrimary);
			}
		}
		& > .Skills {
			padding: 0 0.625rem 0 1rem;
			.grid(3 4; 1 2);
			.text(@ColorBase; 1.25em 1rem @medium);
		}
		& > .Button {
			margin: 0.625rem 0 0 0;
			.grid(1 span);
			.box(block; 100%; auto; none);
			& > .UiButton {
				padding: 1.25rem;
				.border-radius(0.625rem);
				.box(100%);
			}
		}
	}

	@media all and (min-width: @tablet-small) and (max-width: (@laptop-small - 1px)) {
		.box(grid; 100%; auto; none; 2rem 2.5rem; auto; repeat(2; 1fr); stretch; center);
	}

	@media all and (min-width: @laptop-small) and (max-width: (@desktop - 1px)) {
		padding: 3rem 0 0 0;
		.box(grid; 100%; auto; none; 2rem 2.5rem; auto; repeat(3; 1fr); stretch; center);
		& .Employee {
			& > .Name {
				padding: 0 0.625rem 0 1.5rem;
			}
			& > .Skills {
				padding: 0 0.625rem 0 1.5rem;
			}
		}
	}

	@media all and (min-width: @desktop) {
		padding: 4rem 0 0 0;
		.box(grid; 100%; auto; none; 2rem 2.5rem; auto; repeat(4; 1fr); stretch; center);
		& .Employee {
			& > .Name {
				padding: 0 0.625rem 0 2rem;
			}
			& > .Skills {
				padding: 0 0.625rem 0 2rem;
			}
		}
	}
}
</style>
